import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./DownloadCertificate.css";

import SHRMLogo from "../../assets/images/SHRMLogo.png";
import FacebookIcon from "../../assets/icons/facebook.png";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import TwitterIcon from "../../assets/icons/twitter.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import YoutubeIcon from "../../assets/icons/youtube.png";
import { Candidate } from "../../models";
import { retrieveCertificate } from "../../services/api/candidateService";
import PdfViewer from "../PdfViewer";

const DownloadCertificate = () => {
  const { candidateId, certId } = useParams();
  const [certificate, setCertificate] = useState<Candidate>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      if (candidateId && certId) {
        setLoading(true);
        const { data, error } = await retrieveCertificate(candidateId, certId);
        if (error) {
          setError("Invalid candidate or certificate ID");
          setLoading(false);
        } else {
          setCertificate(data);
          setLoading(false);
        }
      }
    };
    fetchCertificate();
  }, [certId, candidateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!certificate) {
    return <div>No certificate found</div>;
  }

  return (
    <div className="page-container">
      <header className="page-header">
        <img src={SHRMLogo} alt="SHRM" className="page-header-logo" />
      </header>
      <div className="page-content">
        <div className="main">
          <div className="card">
            <div className="row no-gutters">
              <div
                onClick={() => {
                  window.open(
                    `${
                      certificate.imageUrl ||
                      certificate["certificates"]?.["v1"]?.["certificatePDFUrl"]
                    }${(window as any).config?.REACT_APP_AZURE_BLOB_SAS_URL}`,
                    "_blank"
                  );
                }}
              >
                <PdfViewer
                  className={"pdf-thumbnail__page"}
                  pdfUrl={`${
                    certificate.imageUrl ||
                    certificate["certificates"]?.["v1"]?.["certificatePDFUrl"]
                  }${(window as any).config?.REACT_APP_AZURE_BLOB_SAS_URL}`}
                />
              </div>
              <div className="col-sm-7">
                <div className="card-body">
                  <h5 className="card-title">{certificate.name}</h5>
                  <p className="card-text">
                    Student ID: {certificate.studentId}
                  </p>
                  <p className="card-text">
                    Program Name: {certificate.subject || certificate.course}
                  </p>
                  <p className="card-text">
                    Date of completion: {certificate.startDate}
                  </p>
                  <p className="card-text">
                    Date of certificate issued: {certificate.endDate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer text-white py-2">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0 p-0">
              <div className="d-flex">
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.facebook.com/SHRMMENA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={FacebookIcon}
                      alt="facebook"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.linkedin.com/company/shrm-mea"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={LinkedInIcon}
                      alt="linkedin"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://twitter.com/SHRMMEA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={TwitterIcon}
                      alt="twitter"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.instagram.com/shrmmena/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={InstagramIcon}
                      alt="instagram"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.youtube.com/channel/UCB1w7_o1vw-5K7OjXpwjTMw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={YoutubeIcon}
                      alt="youtube"
                    />
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right p-0">
              <p className="mb-0 footer-line">
                Email:{" "}
                <a
                  href="mailto:SHRM.MEA@shrm.org"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  SHRM.MEA@shrm.org
                </a>{" "}
                | Landline:{" "}
                <a
                  href="tel:+97143649464"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  +971 43649464
                </a>{" "}
                |{" "}
                <span className="d-inline-block">
                  Copyright &copy; 2024 SHRM. All Rights Reserved.
                </span>{" "}
                |{" "}
                <a
                  href="https://www.shrm.org/about/privacy-policy"
                  className="text-white text-decoration-none"
                  target="_blank"
                  style={{ paddingRight: "2px" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DownloadCertificate;
