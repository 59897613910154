import { useState, ChangeEvent, KeyboardEvent } from "react";
import "./Search.css";
import QrReader from "modern-react-qr-reader";
import { Candidate } from "../../models";
import SHRMLogo from "../../assets/images/SHRMLogo.png";
import FacebookIcon from "../../assets/icons/facebook.png";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import TwitterIcon from "../../assets/icons/twitter.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import YoutubeIcon from "../../assets/icons/youtube.png";
import {
  searchCandidates,
  searchOldCertificates,
} from "../../services/api/candidateService";
import PdfViewer from "../PdfViewer";

const Search = () => {
  const [result, setResult] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const makeApiCall = async (searchInput: string) => {
    if (searchInput && searchInput.trim() !== "") {
      const { data, error } = await searchCandidates(searchInput);
      if (error) {
        setError("Failed to fetch certificates");
      } else {
        setCandidates(data || []);
      }
    }
  };

  const fetchOldCertificate = async (
    studentId: string,
    certificateNumber: string
  ) => {
    const { data, error } = await searchOldCertificates(
      studentId,
      certificateNumber
    );
    if (error) {
      setError("Failed to fetch certificates");
    } else {
      setCandidates(data || []);
    }
  };

  const handleSearch = () => {
    setShowScanner(false);
    makeApiCall(searchValue);
  };

  const showQrCodeScanner = () => {
    setCandidates([]);
    setSearchValue("");
    setShowScanner((prevShowScanner) => !prevShowScanner);
  };

  const handleScan = (data: string | null) => {
    if (data) {
      setResult(data);
      console.log(data);
      if (data.includes("http")) {
        window.open(data);
      } else {
        const certInfo = JSON.parse(data);
        if (certInfo && certInfo.CertificateNumber) {
          setShowScanner(false);
          makeApiCall(certInfo.CertificateNumber);
        }
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const keyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      console.log("value", (e.target as HTMLInputElement).value);
      makeApiCall((e.target as HTMLInputElement).value);
    }
  };

  return (
    <div className="page-container">
      <header className="page-header">
        <img src={SHRMLogo} alt="SHRM" className="page-header-logo" />
      </header>
      <div className="page-content">
        <div className="main">
          <div className="input-group">
            <input
              type="text"
              onKeyDown={keyPress}
              className="form-control search-input"
              onChange={handleOnChange}
              value={searchValue}
              placeholder="Please enter Email or Student ID to search."
            />
            <div className="input-group-append">
              <button
                onClick={handleSearch}
                className="btn btn-secondary mr-1"
                type="button"
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                onClick={showQrCodeScanner}
                className="btn btn-secondary ml-1"
                type="button"
              >
                <i className="fa fa-qrcode"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          {showScanner && (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              facingMode={"environment"}
              style={{ width: "50%" }}
            />
          )}
        </div>

        <div>
          {candidates.length !== 0 ? (
            <div className="container d-flex flex-wrap">
              {candidates.map((candidate, index) => (
                <div key={index} className="mt-3 mb-3">
                  <div className="card">
                    <div className="row no-gutters">
                      <div
                        onClick={() => {
                          window.open(
                            `${
                              candidate.imageUrl ||
                              candidate["certificates"]?.["v1"]?.[
                                "certificatePDFUrl"
                              ]
                            }${
                              (window as any).config
                                ?.REACT_APP_AZURE_BLOB_SAS_URL
                            }`,
                            "_blank"
                          );
                        }}
                      >
                        <PdfViewer
                          className={"pdf-thumbnail__page"}
                          pdfUrl={`${
                            candidate.imageUrl ||
                            candidate["certificates"]?.["v1"]?.[
                              "certificatePDFUrl"
                            ]
                          }${
                            (window as any).config?.REACT_APP_AZURE_BLOB_SAS_URL
                          }`}
                        />
                      </div>
                      <div className="col-sm-7">
                        <div className="card-body">
                          <h5 className="card-title">{candidate.name}</h5>
                          <p className="card-text">
                            Student ID: {candidate.studentId}
                          </p>
                          <p className="card-text">
                            {" "}
                            Program Name:{" "}
                            {candidate.subject || candidate.course}
                          </p>
                          <p className="card-text">
                            {" "}
                            Date of completion: {candidate.startDate}
                          </p>
                          <p className="card-text">
                            {" "}
                            Date of certificate issued: {candidate.endDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-resultp">No result found</p>
          )}
        </div>
      </div>
      <footer className="footer text-white py-2">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0 p-0">
              <div className="d-flex">
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.facebook.com/SHRMMENA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={FacebookIcon}
                      alt="facebook"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.linkedin.com/company/shrm-mea"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={LinkedInIcon}
                      alt="linkedin"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://twitter.com/SHRMMEA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={TwitterIcon}
                      alt="twitter"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.instagram.com/shrmmena/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={InstagramIcon}
                      alt="instagram"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.youtube.com/channel/UCB1w7_o1vw-5K7OjXpwjTMw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={YoutubeIcon}
                      alt="youtube"
                    />
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right p-0">
              <p className="mb-0 footer-line">
                Email:{" "}
                <a
                  href="mailto:SHRM.MEA@shrm.org"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  SHRM.MEA@shrm.org
                </a>{" "}
                | Landline:{" "}
                <a
                  href="tel:+97143649464"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  +971 43649464
                </a>{" "}
                |{" "}
                <span className="d-inline-block">
                  Copyright &copy; 2024 SHRM. All Rights Reserved.
                </span>{" "}
                |{" "}
                <a
                  href="https://www.shrm.org/about/privacy-policy"
                  className="text-white text-decoration-none"
                  target="_blank"
                  style={{ paddingRight: "2px" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Search;
