import React, { useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import Search from "../components/Search";
import DownloadCertificate from "../components/DownloadCertificate";

const AppRoutes = () => (
  <Router>
    <Routes>
      {/* Default route for the Search component */}
      <Route path="/" element={<Search />} />
      {/* Route for downloading certificates */}
      <Route
        path="/candidates/:candidateId/certificates/:certId"
        element={<DownloadCertificate />}
      />
      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<h2>404 Not Found</h2>} />
    </Routes>
  </Router>
);

export default AppRoutes;
