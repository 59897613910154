import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

interface PdfViewerProps {
  pdfUrl: string;
  className: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl, className }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  // Set the PDF worker source to handle the PDF rendering
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }, []);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  return (
    <>
      <Document
        file={pdfUrl}
        onLoadSuccess={onLoadSuccess}
        className={className}
      >
        {/* Render the first page of the PDF */}
        <Page pageNumber={pageNumber} width={250} />
      </Document>
    </>
  );
};

export default PdfViewer;
