import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import reportWebVitals from "./reportWebVitals";

// Fetch runtime configuration
fetch("/runtime-config.json")
  .then((response) => {
    if (!response.ok) {
      throw new Error(
        `Failed to fetch runtime-config.json: ${response.status}`
      );
    }
    return response.json();
  })
  .then((config) => {
    // Attach runtime config to the global `window` object
    (window as any).config = config;

    // Render the React app after runtime configuration is loaded
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Error loading runtime configuration:", error);
    // Optionally handle errors, e.g., render a fallback UI
  });

// Start measuring performance
reportWebVitals();
